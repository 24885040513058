<template>
  <div class="place-summary-info-wrapper">
    <div class="header flex flex-row items-center justify-between">
      <p class="title">공간번호 {{ data.number }}</p>

      <div>
        <div
          class="flex items-center justify-center cursor-pointer"
          @click="onFavorite"
        >
          <eva-icon
            :name="isFavorite ? 'star' : 'star-outline'"
            :width="24"
            :height="24"
            :fill="isFavorite ? '#293bf0' : '#616161'"
          />
        </div>
      </div>
    </div>

    <h3 class="name">
      {{ data.title }}
    </h3>

    <h5 class="place">
      {{ data.addr }}
    </h5>

    <div class="info flex flex-row items-center">
      <p class="flex flex-row items-center">
        <img src="@/assets/detail-date.png" />
        {{ date }}
      </p>

      <p class="flex flex-row items-center">
        <img src="@/assets/detail-view.png" />
        {{ getViewCnt }}
      </p>
    </div>

    <!-- <div class="button-group flex flex-row items-center">
      <button @click="onChat" class="flex-1 flex items-center justify-center">
        <eva-icon
          class="mr-1"
          name="message-circle-outline"
          :width="20"
          :height="20"
          :fill="'#fff'"
        />임대인과 채팅상담
      </button>
    </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ko";
import services from "@/services";

dayjs.locale("ko");
dayjs.extend(relativeTime);

export default {
  name: "PlaceSummaryInfo",
  props: {
    data: {
      type: Object,
      default: () => ({
        number: "",
        title: "",
        addr: "",
        viewCnt: 0,
      }),
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    onChat: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFavorite: false,
    };
  },
  mounted() {
    this.isFavorite =
      this.data.hasUserSubscribePlace?.filter((s) => s.userId === this.userId)
        .length > 0;
  },
  methods: {
    async onFavorite() {
      if (!this.isLoggedIn) {
        return;
      }

      if (this.isFavorite) {
        await services.placeRemoveSubscribe(this.id);
        this.isFavorite = false;
        this.$toast.open("찜해제 되었습니다");
      } else {
        await services.placeAddSubscribe(this.userId, this.id);
        this.isFavorite = true;
        this.$toast.open("찜이 되었습니다");
      }
    },
  },
  computed: {
    getViewCnt() {
      if (this.data.viewCnt) {
        return this.data.viewCnt.toLocaleString();
      }
      return 0;
    },
    date() {
      const { createdAt } = this.data;
      if (createdAt) {
        return `${dayjs(createdAt).fromNow()} 등록`;
      }
      return "";
    },
    ...mapState("user", ["userId", "isLoggedIn"]),
  },
};
</script>

<style scoped lang="scss">
.place-summary-info-wrapper {
  .header {
    margin-top: 25px;
    margin-bottom: 25px;

    .title {
      font-size: 14px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: -0.14px;
      text-align: left;
      color: #a0a0a0;
    }
  }

  .name {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -1.2px;
    text-align: left;
    color: #202020;

    margin-bottom: 18px;
  }

  .place {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.14px;
    text-align: left;
    color: #202020;

    margin-bottom: 20px;
  }

  .info {
    margin-bottom: 27px;

    img {
      margin-right: 6px;
    }

    p {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.12px;
      text-align: left;
      color: #a5a5a5;

      &:first-child {
        margin-right: 11px;
      }
    }
  }

  .button-group {
    margin-bottom: 25px;

    button {
      height: 52px;
      background-color: #5463e8;

      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.96;
      letter-spacing: -0.56px;
      text-align: left;
      color: #fefefe;
    }
  }
}

@media (max-width: 639px) {
  .place-summary-info-wrapper {
    .name {
      font-size: 18px;
    }
    .place {
      font-size: 12px;
    }
  }
}
</style>
