<template>
  <Layout :title="'공간 경쟁입찰'">
    <div class="flex flex-col place-join-offer-wrapper">
      <div class="place-join-offer-section-1 flex flex-row justify-start">
        <div
          class="image"
          :style="{
            backgroundImage: image ? `url(${image})` : '',
          }"
        />
        <PlaceSummaryInfo :data="data" />
      </div>

      <div class="place-join-offer-section-2">
        {{ data.subTitle }}
      </div>

      <div class="place-join-offer-section-3">
        <div class="item flex flex-row">
          <p class="label">분류</p>
          <p class="value flex-1">{{ category }}</p>
        </div>

        <div class="item flex flex-row">
          <p class="label">임대평수</p>
          <p class="value flex-1">
            {{ leasableArea }}평 ({{ leasableArea * 3.3 }}m<sup>2</sup>)
          </p>
        </div>

        <!-- <div 
          v-if="data.hasPlaceDetail && data.hasPlaceDetail.buildingFloor !== null"
          class="item flex flex-row"
        >
          <p class="label">해당층</p>
          <p class="value flex-1">{{ data.hasPlaceDetail.buildingFloor }}층</p>
        </div> -->

        <div
          v-if="data.hasPlaceDetail && data.hasPlaceDetail.bathroomCnt !== null"
          class="item flex flex-row"
        >
          <p class="label">화장실</p>
          <p class="value flex-1">{{ data.hasPlaceDetail.bathroomCnt }}개</p>
        </div>

        <div
          v-if="data.hasPlaceDetail && data.hasPlaceDetail.parkingCnt !== null"
          class="item flex flex-row"
        >
          <p class="label">주차댓수</p>
          <p class="value flex-1">{{ data.hasPlaceDetail.parkingCnt }}대</p>
        </div>

        <div
          v-if="data.hasPlaceDetail && data.hasPlaceDetail.builtIn !== null"
          class="item flex flex-row"
        >
          <p class="label">빌트인</p>
          <p class="value flex-1">{{ data.hasPlaceDetail.builtIn }}</p>
        </div>

        <!-- <div
          v-if="data.hasPlaceDetail && data.hasPlaceDetail.isElevator !== null"
          class="item flex flex-row"
        >
          <p class="label">엘레베이터 여부</p>
          <p class="value flex-1">유</p>
        </div> -->

        <div
          v-if="data.hasPlaceDetail && data.hasPlaceDetail.direction !== null"
          class="item flex flex-row"
        >
          <p class="label">방향</p>
          <p class="value flex-1">{{ data.hasPlaceDetail.direction }}</p>
        </div>

        <div
          v-if="data.hasPlaceDetail && data.hasPlaceDetail.heatingType !== null"
          class="item flex flex-row"
        >
          <p class="label">난방방식</p>
          <p class="value flex-1">{{ data.hasPlaceDetail.heatingType }}</p>
        </div>

        <div
          v-if="
            data.hasPlaceDetail && data.hasPlaceDetail.completionYear !== null
          "
          class="item flex flex-row"
        >
          <p class="label">준공년도</p>
          <p class="value flex-1">{{ data.hasPlaceDetail.completionYear }}년</p>
        </div>
      </div>

      <div class="place-join-offer-section-4 flex flex-row justify-around">
        <div class="item">
          <div class="label flex flex-row items-center">
            <img src="@/assets/offer-2.png" />
            경쟁입찰 참여인원
          </div>
          <div class="value w-full">{{ offers.length }}명</div>
        </div>

        <div class="item">
          <div class="label flex flex-row items-center">
            <img src="@/assets/offer-3.png" />
            생성일
          </div>
          <div class="value w-full">
            {{ date }}
          </div>
        </div>
      </div>

      <div class="place-join-offer-section-5">
        <div class="price flex flex-row items-center">
          <span>임대료(월단위)</span>
          <div class="flex-1">
            <Input
              v-model="price"
              type="number"
              :min="minPrice"
              :max="1000000000"
              :placeholder="
                `${minPrice.toLocaleString()}만원 이상, 1만원 단위로 입력가능`
              "
            />
          </div>
          <p>만원</p>
        </div>

        <div class="price flex flex-row items-center">
          <span>보증금</span>
          <div class="flex-1">
            <Input
              v-model="utilities"
              type="number"
              :min="1"
              :max="1000000000"
              placeholder="1만원 이상, 1만원 단위로 입력가능"
            />
          </div>
          <p>만원</p>
        </div>

        <div class="content">
          <Textarea v-model="contents" placeholder="특이사항" />
        </div>
      </div>

      <button
        @click="onSubmit"
        class="w-full flex justify-center items-center"
        :disabled="isLoading"
      >
        <span v-if="!isLoading">경쟁입찰 참여하기</span>
        <PulseLoader :loading="isLoading" :size="'8px'" :color="'white'" />
      </button>
    </div>
  </Layout>
</template>

<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import Layout from "@/components/Layout.vue";
import PlaceSummaryInfo from "@/components/PlaceSummaryInfo.vue";
import Input from "@/components/Input.vue";
import Textarea from "@/components/Textarea.vue";
import services from "@/services";
import utils from "@/utils";
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
  name: "PlaceOffer",
  components: {
    PulseLoader,
    Layout,
    PlaceSummaryInfo,
    Input,
    Textarea,
  },
  data() {
    return {
      data: {},
      offers: [],
      price: null,
      utilities: null,
      contents: "",
      isLoading: false,
    };
  },
  mounted() {
    const { id } = this.$route.params;
    this.getData(id);
  },
  methods: {
    getData: async function(id) {
      const [data, offers] = await Promise.all([
        services.placeDetail(id),
        services.placeJoinOfferList({ placeId: id }),
      ]);
      this.data = data;
      this.offers = offers;
    },
    async onSubmit() {
      if (this.price < this.minPrice) {
        this.showError(
          `임대료를 ${this.minPrice.toLocaleString()}만원 이상 입력해주세요`
        );
        return;
      } else if (!this.utilities) {
        this.showError("보증금을 입력해주세요");
        return;
      } else if (!this.contents) {
        this.showError("특이사항을 입력해주세요");
        return;
      }

      this.isLoading = true;
      try {
        const success = await services.placeJoinOfferCreate({
          userId: this.userId,
          placeId: this.data.id,
          price: this.price * 10000,
          utilities: this.utilities * 10000,
          contents: this.contents,
        });
        if (success) {
          this.show("입찰참여가 완료되었습니다");
          this.$router.go(-1);
        } else {
          this.showError("문제가 발생했습니다. 나중에 다시 시도해주세요");
        }
      } catch (e) {
        this.showError("문제가 발생했습니다. 나중에 다시 시도해주세요");
      } finally {
        this.isLoading = false;
      }
    },
    show(message) {
      this.$toast.open({ message, type: "default" });
    },
    showError(message) {
      this.$toast.open({ message, type: "error" });
    },
  },
  computed: {
    image() {
      const images = utils.getPlaceImages(this.data);
      if (images.length > 0) {
        return images[0];
      }
      return "";
    },
    category() {
      const { hasPlaceClassification } = this.data;
      if (hasPlaceClassification) {
        return hasPlaceClassification.title;
      }
      return "";
    },
    leasableArea() {
      let area = 0;
      const { hasPlaceDetail, size } = this.data;
      if (hasPlaceDetail) {
        const { netLeasableArea, leaseableArea } = hasPlaceDetail;
        if (leaseableArea) {
          area = leaseableArea;
        } else if (netLeasableArea) {
          area = netLeasableArea;
        }
      } else if (size) {
        area = size;
      }

      return area;
    },
    minPrice() {
      const { minPrice: price } = this.data;
      if (price) {
        return price / 10000;
      }
      return 1;
    },
    date() {
      if (this.data.createdAt) {
        return dayjs(this.data.createdAt).format("YYYY-MM-DD");
      }
      return "";
    },
    ...mapState("user", ["userId"]),
  },
};
</script>

<style scoped lang="scss">
.place-join-offer-wrapper {
  max-width: calc(786px + 40px + 40px);
  width: 100%;
  padding: 0 40px;
  margin-top: 55px;
  margin-bottom: 100px;

  button {
    height: 62px;
    border: solid 1px #ddd;
    background-color: #283aef;

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}

.place-join-offer-section-1 {
  padding: 25px 25px 30px 25px;
  border: solid 1px #c9c9c9;
  margin-bottom: 20px;

  .image {
    width: 371px;
    height: 229px;
    background-color: #ddd;
    margin-right: 45px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.place-join-offer-section-2 {
  background-color: #f7f7f7;
  padding: 15px 30px;
  margin-bottom: 10px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #333;
}

.place-join-offer-section-3 {
  background-color: #f7f7f7;
  padding: 15px 30px;
  margin-bottom: 30px;

  .item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .label {
      width: 110px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #767676;
    }

    .value {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.14px;
      text-align: left;
      color: #333;
    }
  }
}

.place-join-offer-section-4 {
  background-color: #f7f7f7;
  padding: 15px 30px;
  margin-bottom: 40px;

  .item {
    .label {
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.79;
      letter-spacing: normal;
      text-align: left;
      color: #838383;

      img {
        margin-right: 5px;
      }
    }

    .vaule {
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      text-align: left;
      color: #333;
    }
  }
}

.place-join-offer-section-5 {
  margin-bottom: 15px;

  .price {
    span {
      width: 120px;
      font-size: 15px;
      font-weight: 500;
      color: #222;
    }

    margin-bottom: 15px;

    p {
      margin-left: 10px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #d2d2d2;
    }
  }
}

@media (max-width: 639px) {
  .place-join-offer-wrapper {
    max-width: 100%;
    padding: 0 20px;
  }
  .place-join-offer-section-1 {
    padding: 10px 15px;
    flex-direction: column;
    .image {
      max-width: 250px;
      width: 100%;
      height: 200px;
      margin: 0 auto;
    }
  }
  .place-join-offer-section-2 {
    padding: 10px 15px;
    font-size: 12px;
  }
  .place-join-offer-section-3 {
    padding: 10px 15px;
    margin-bottom: 20px;
    .item {
      .label {
        font-size: 12px;
        width: 80px;
      }
      .value {
        font-size: 12px;
      }
    }
  }
  .place-join-offer-section-4 {
    padding: 10px 15px;
    margin-bottom: 20px;
    flex-direction: column;
    gap: 10px;
    .item {
      .label {
        font-size: 12px;
        line-height: 1.2;
        margin-bottom: 5px;
        img {
          width: 13px;
        }
      }
      .value {
        font-size: 12px;
      }
    }
  }
  .place-join-offer-section-5 {
    .price {
      flex-wrap: wrap;
      span {
        font-size: 13px;
        width: 100%;
      }
    }
  }
}
</style>
